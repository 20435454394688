/* eslint-disable no-magic-numbers */
export const EUROJACKPOT = {
	min: 1,
	max: 50,
	minSecond: 1,
	maxSecond: 12,
	chanceMin: 1,
	chanceMax: 9,
	perLine: 5,
	drawNumbers: 5,
	secondDrawNumbers: 2,
	guessedNumbers: 5,
	guessedSecondNumbers: 2,
	guessedNumbersChance: 6,
	minColumns: 1,
	maxColumns: 6,
	// uhodnotych + dodatkove - https://vyhraj.cz/loterie/eurojackpot/
	priceTable: {
		"5+2": 10e8,
		"5+1": 28544400,
		"5+0": 8048857,
		"4+2": 147514,
		"4+1": 8710,
		"3+2": 4204,
		"4+0": 2506,
		"2+2": 655,
		"3+1": 493,
		"3+0": 372,
		"1+2": 338,
		"2+1": 243,
	},
	// tabulka sanci - posledni cislo
	chanceTable: {
		0: 0,
		1: 120,
		2: 200,
		3: 2000,
		4: 20000,
		5: 200000,
		6: 2000000,
	},
	pricePerColumn: 60,
	chancePrice: 40,
};
