/* eslint-disable no-magic-numbers */
import { generateNumbersInRange } from "~/utils/utils";

// 1-4 sloupce, mozna sance, kazdy sloupec 1-10 cisel hadam, vlastni sazka na sloupec + moznost kralovske hry
export const STASTNYCH10 = {
	min: 1,
	max: 80,
	chanceMin: 1,
	chanceMax: 9,
	perLine: 8,
	drawNumbers: 20,
	guessedNumbersMin: 1,
	guessedNumbersMax: 10,
	guessedNumbersChance: 6,
	minColumns: 1,
	maxColumns: 4,
	minDrawCount: 1,
	maxDrawCount: 10,
	// uhodnotych + dodatkove - https://www.hazardni-hry.eu/loterie/sportka.html
	priceTableKing: {
		10: {
			10: 300000,
			9: 15000,
			8: 750,
			7: 30,
			6: 15,
			5: 6,
			4: 2,
			3: 2,
			2: 2,
			1: 6,
		},
		9: {
			9: 75000,
			8: 3000,
			7: 300,
			6: 30,
			5: 6,
			4: 2,
			3: 2,
			2: 2,
			1: 8,
		},
		8: {
			8: 3000,
			7: 1000,
			6: 100,
			5: 15,
			4: 5,
			3: 2,
			2: 2,
			1: 3,
		},
		7: {
			7: 6000,
			6: 200,
			5: 20,
			4: 10,
			3: 4,
			2: 3,
			1: 3,
		},
		6: {
			6: 1500,
			5: 50,
			4: 10,
			3: 5,
			2: 5,
			1: 5,
		},
		5: {
			5: 400,
			4: 30,
			3: 10,
			2: 5,
			1: 5,
		},
		4: {
			4: 120,
			3: 25,
			2: 7,
			1: 5,
		},
		3: {
			3: 50,
			2: 20,
			1: 20,
		},
		2: {
			2: 50,
			1: 10,
		},
		1: {
			1: 40,
		},
	},
	priceTable: {
		10: {
			10: 200000,
			9: 10000,
			8: 500,
			7: 20,
			6: 10,
			5: 3,
		},
		9: {
			9: 50000,
			8: 2000,
			7: 200,
			6: 20,
			5: 3,
		},
		8: {
			8: 20000,
			7: 400,
			6: 40,
			5: 4,
			4: 1,
		},
		7: {
			7: 4000,
			6: 100,
			5: 10,
			4: 2,
		},
		6: {
			6: 600,
			5: 20,
			4: 2,
			3: 1,
		},
		5: {
			5: 200,
			4: 16,
			3: 2,
		},
		4: {
			4: 50,
			3: 8,
		},
		3: {
			3: 16,
			2: 2,
		},
		2: {
			2: 8,
		},
		1: {
			1: 2,
		},
		0: {
			// pocet tipovanych cisel
			10: 1,
			9: 1,
			8: 1,
			7: 1,
			6: 1,
			5: 0,
			4: 0,
			3: 0,
			2: 0,
			1: 0,
		},
	},
	// tabulka sanci - posledni cislo
	chanceTable: {
		0: 0,
		1: 20,
		2: 50,
		3: 500,
		4: 5000,
		5: 50000,
		6: 1000000,
	},
	pricesPerColumn: generateNumbersInRange(10, 200, 10),
	// sloupec 10, kralovsky 20
	kingGamePriceRatio: 2,
	chancePrice: 10,
};
